import React from "react"
import Layout from "../containers/Layout"
import BoxCollection from "../containers/BoxCollection"
import Box from "../components/Box"
import boxColors from "../styles/box-colors.module.scss"

export default () => (
  <Layout>
    <BoxCollection title="Generative Art" colorClassName={boxColors.grey}>
      <Box link="/generative-art/4" label="4" preview />
      <Box link="/generative-art/5" label="5" preview />
      <Box link="/generative-art/6" label="6" preview />
      <Box link="/generative-art/8" label="8" preview />
    </BoxCollection>
  </Layout>
)
